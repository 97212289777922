import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from 'vue'

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock('svg', {
    viewBox: '0 0 20 20',
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg'
  }, [
    _createElementVNode('g', { 'clip-path': 'url(#li)' }, [
      _createElementVNode('path', {
        d: 'M15.28 15.28h-2.22V11.8c0-.83-.02-1.9-1.16-1.9-1.16 0-1.33.9-1.33 1.84v3.54H8.34V8.13h2.14v.97h.03a2.34 2.34 0 0 1 2.1-1.16c2.25 0 2.67 1.49 2.67 3.41v3.93ZM5.84 7.15a1.29 1.29 0 1 1 0-2.58 1.29 1.29 0 0 1 0 2.58Zm1.1 8.13H4.73V8.13h2.23v7.15ZM16.4 2.5H3.61c-.62 0-1.11.48-1.11 1.08v12.84c0 .6.5 1.08 1.1 1.08H16.4a1.1 1.1 0 0 0 1.11-1.08V3.58c0-.6-.5-1.08-1.11-1.08Z',
        fill: 'currentColor'
      })
    ]),
    _createElementVNode('defs', null, [
      _createElementVNode('clipPath', { id: 'li' }, [
        _createElementVNode('path', {
          fill: '#fff',
          d: 'M0 0h20v20H0z'
        })
      ])
    ])
  ]))
}
