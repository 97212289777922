import Plausible, { type EventOptions, type PlausibleOptions } from 'plausible-tracker'
import { defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin(() => {
  const options = {
    domain: 'outfit7.com',
    hashMode: true,
    trackLocalhost: false
  }

  let track: (eventName: string, options?: EventOptions, eventData?: PlausibleOptions) => void

  return {
    provide: {
      plausible: () => {
        const { enableAutoPageviews, trackEvent } = Plausible(options)
        enableAutoPageviews()
        // target blank stops working afters this, also age gate…
        // enableAutoOutboundTracking();
        track = trackEvent
      },

      event: (eventName: string, options?: EventOptions, eventData?: PlausibleOptions) => {
        track(eventName, options, eventData)
      }
    }
  }
})
