<template>
  <NuxtLayout>
    <div class="relative overflow-hidden">
      <img src="~/assets/img/404.jpg"
           alt="404 background"
           class="absolute inset-0 object-cover w-full h-full"
      >
      <Container class="relative z-10 mt-32 mb-10 lg:mb-20 lg:mt-56 lg:min-h-screen">
        <h1 class="text-8xl lg:text-[128px] text-gray-100">
          404
        </h1>
        <p class="max-w-xs mt-12 text-xl text-gray-100 lg:max-w-xl lg:text-3xl">
          You didn't break the internet, but we can't find what you are looking for.
        </p>
        <ButtonBase label="Read our latest posts"
                    type="filled"
                    href="/blog"
                    class="flex mt-12"
        />
      </Container>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
import { type RouteLocationNormalized } from 'vue-router'
import { useRouter, clearError } from '#imports'

const router = useRouter()

const navigationGuard = (to: RouteLocationNormalized) => {
  unregisterNavigationGuard()
  clearError({ redirect: to.fullPath })
}

const unregisterNavigationGuard = router.beforeEach(navigationGuard)
</script>
