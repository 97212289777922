import Lenis from 'lenis'
import { defineNuxtPlugin } from '#imports'

/**
 * In order for Lenis to work correctly, it needs to be initialized in the
 * onMounted hook, and destroyed in the onUnmounted hook on every page.
 *
 * Othwerwise, the scroll will disrupt the page scroll position when navigating.
 *
 * To make this work with the scrollTo Lenis function we define it as a plugin
 * with 2 providers.
 *
 * Lenis also has some drawbacks:
 *  - no support of CSS scroll-snap
 *  - erase browser previous and next trackpad gestures
 *  - can only run 60fps maximum on Safari (source)
 *
 */
export default defineNuxtPlugin(() => {
  let lenis: Lenis

  return {
    provide: {
      /**
       * Simply calls the lenis on scroll function
       * This must be used on all anchor links in order for them to work all the time
       * (even when Lenis is still easing out)
       */
      lenisScroll: (anchor: string, offset = 128) => {
        lenis?.scrollTo(anchor, { offset: -offset })
      },

      /**
       * This must be called on every page in the onMounted hook
       *
       * It will initialize Lenis and start the raf loop.
       * In case lenis is already initialized, it will destroy it first.
       */
      lenisMount: () => {
        if (lenis) {
          lenis.stop()
          lenis.destroy()
        }

        lenis = new Lenis({
          duration: 1.5,
          easing: t => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)), // https://easings.net
          touchMultiplier: 2
        })

        function raf(time: number) {
          lenis.raf(time)
          requestAnimationFrame(raf)
        }

        requestAnimationFrame(raf)
      }
    }
  }
})
