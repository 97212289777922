import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(nuxtApp => {
  const { vueApp } = nuxtApp

  Sentry.init({
    app: [vueApp],
    dsn: 'https://140396c2893347e5abf172a0583263ea@o446669.ingest.sentry.io/4504440989614080',
    release: nuxtApp.$config.public.globalO7Release,
    integrations: [
      Sentry.browserTracingIntegration({ router: useRouter() })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
    ignoreErrors: [
      /ibFindAllVideos/
    ],
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, { event, hint })
      }
      // Continue sending to Sentry
      return event
    }
  })

  vueApp.mixin(Sentry.createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] }))
  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
    attachErrorHandler: true
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException
    }
  }
})
