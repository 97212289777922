import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from 'vue'

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock('svg', {
    viewBox: '0 0 20 20',
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg'
  }, [
    _createElementVNode('g', { 'clip-path': 'url(#ig)' }, [
      _createElementVNode('path', {
        d: 'M10 7.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm0-1.67a4.17 4.17 0 1 1 0 8.34 4.17 4.17 0 0 1 0-8.34Zm5.42-.2a1.04 1.04 0 1 1-2.09 0 1.04 1.04 0 0 1 2.09 0ZM10 3.33c-2.06 0-2.4 0-3.36.05-.65.03-1.09.12-1.5.28-.36.14-.62.3-.9.58a2.4 2.4 0 0 0-.58.9c-.16.41-.25.85-.28 1.5-.04.92-.05 1.24-.05 3.36 0 2.06 0 2.4.05 3.36.03.65.12 1.09.28 1.5.14.36.3.62.58.9.28.28.54.44.9.58.41.16.85.25 1.5.28.92.04 1.24.05 3.36.05 2.06 0 2.4 0 3.36-.05.65-.03 1.09-.12 1.5-.28.36-.14.62-.3.9-.58.28-.28.44-.54.58-.9.16-.41.25-.85.28-1.5.04-.92.05-1.24.05-3.36 0-2.06 0-2.4-.05-3.36a4.54 4.54 0 0 0-.28-1.5 2.43 2.43 0 0 0-.58-.9 2.4 2.4 0 0 0-.9-.58c-.41-.16-.85-.25-1.5-.28-.92-.04-1.24-.05-3.36-.05Zm0-1.66c2.26 0 2.55 0 3.44.05.88.04 1.49.18 2.02.38.55.22 1.01.5 1.47.96.43.42.76.93.97 1.48.2.53.34 1.14.38 2.03.04.88.05 1.17.05 3.43a58 58 0 0 1-.05 3.44c-.04.88-.18 1.49-.38 2.02-.21.55-.54 1.06-.96 1.47a4.1 4.1 0 0 1-1.48.97c-.53.2-1.14.34-2.03.38-.88.04-1.17.05-3.43.05a58 58 0 0 1-3.44-.05 6.16 6.16 0 0 1-2.02-.38 4.07 4.07 0 0 1-1.48-.96 4.09 4.09 0 0 1-.96-1.48 6.13 6.13 0 0 1-.38-2.03c-.04-.88-.05-1.17-.05-3.43 0-2.26 0-2.55.05-3.43.04-.9.18-1.5.38-2.03.21-.55.54-1.06.96-1.48.42-.42.93-.75 1.48-.96.53-.2 1.14-.34 2.02-.38.9-.04 1.18-.05 3.44-.05Z',
        fill: 'currentColor'
      })
    ]),
    _createElementVNode('defs', null, [
      _createElementVNode('clipPath', { id: 'ig' }, [
        _createElementVNode('path', {
          fill: '#fff',
          d: 'M0 0h20v20H0z'
        })
      ])
    ])
  ]))
}
