import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from 'vue'

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock('svg', {
    viewBox: '0 0 20 20',
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg'
  }, [
    _createElementVNode('g', { 'clip-path': 'url(#yt)' }, [
      _createElementVNode('path', {
        d: 'M17.95 5.42c.38 1.48.38 4.58.38 4.58s0 3.1-.38 4.59a2.34 2.34 0 0 1-1.61 1.68c-1.43.4-6.34.4-6.34.4s-4.91 0-6.34-.4a2.34 2.34 0 0 1-1.61-1.68C1.67 13.1 1.67 10 1.67 10s0-3.1.38-4.58c.2-.83.83-1.47 1.61-1.69 1.43-.4 6.34-.4 6.34-.4s4.91 0 6.34.4c.79.22 1.4.87 1.61 1.69Zm-9.62 7.5 5-2.92-5-2.92v5.84Z',
        fill: 'currentColor'
      })
    ]),
    _createElementVNode('defs', null, [
      _createElementVNode('clipPath', { id: 'yt' }, [
        _createElementVNode('path', {
          fill: '#fff',
          d: 'M0 0h20v20H0z'
        })
      ])
    ])
  ]))
}
