import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from 'vue'

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock('svg', {
    viewBox: '0 0 20 20',
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg'
  }, [
    _createElementVNode('path', {
      d: 'M2.5 15.83h15v1.67h-15v-1.67Zm8.33-4.85 5.06-5.06 1.18 1.17L10 14.17 2.93 7.1 4.1 5.92l5.06 5.05v-9.3h1.66v9.3Z',
      fill: 'currentColor'
    })
  ]))
}
