import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from 'vue'

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock('svg', {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 24 24'
  }, [
    _createElementVNode('path', {
      fill: 'none',
      d: 'M0 0h24v24H0z'
    }),
    _createElementVNode('path', {
      d: 'm17.66 14.83-1.42-1.42L17.66 12A4 4 0 1 0 12 6.34l-1.41 1.42-1.42-1.42 1.42-1.41a6 6 0 0 1 8.48 8.48l-1.41 1.42zm-2.83 2.83-1.42 1.41a6 6 0 1 1-8.48-8.48l1.41-1.42 1.42 1.42L6.34 12A4 4 0 1 0 12 17.66l1.41-1.42 1.42 1.42zm0-9.9 1.41 1.41-7.07 7.07-1.41-1.41 7.07-7.07z',
      fill: 'currentColor'
    })
  ]))
}
