import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from 'vue'

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock('svg', {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 24 24'
  }, [
    _createElementVNode('path', {
      fill: 'none',
      d: 'M0 0h24v24H0z'
    }),
    _createElementVNode('path', { d: 'm12 10.59 4.95-4.95 1.41 1.41L13.41 12l4.95 4.95-1.41 1.41L12 13.41l-4.95 4.95-1.41-1.41L10.59 12 5.64 7.05l1.41-1.41z' })
  ]))
}
