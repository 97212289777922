import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from 'vue'

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock('svg', {
    width: '24',
    height: '24',
    viewBox: '0 0 24 24',
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg'
  }, [
    _createElementVNode('g', { 'clip-path': 'url(#clip0_4859_73683)' }, [
      _createElementVNode('path', {
        d: 'M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z',
        fill: '#FCFCFC'
      }),
      _createElementVNode('path', {
        d: 'M18.782 10.4348C18.4168 10.4348 17.9645 10.4348 17.2167 10.4348C17.2167 13.3163 14.8808 15.6522 11.9994 15.6522C9.11783 15.6522 6.782 13.3163 6.782 10.4348C6.27763 10.4348 5.72108 10.4348 5.2168 10.4348C5.2168 13.5386 7.30161 16.1555 10.1474 16.9615C9.83539 17.5541 9.88292 18.3003 10.3286 18.8565C10.914 18.3873 11.52 17.9017 12.0342 17.4897C12.5483 17.9017 13.1544 18.3874 13.7397 18.8565C14.1898 18.2948 14.2341 17.5391 13.9117 16.9436C16.7263 16.1179 18.782 13.5166 18.782 10.4348Z',
        fill: '#6DA544'
      }),
      _createElementVNode('path', {
        d: 'M7.82617 9.91291C7.82617 9.91291 7.82617 12.5216 10.4349 12.5216L10.9566 13.0433H12.0001C12.0001 13.0433 12.5218 11.4781 13.5653 11.4781C13.5653 11.4781 13.5653 10.4346 14.6087 10.4346C15.6522 10.4346 16.174 10.4346 16.174 10.4346C16.174 10.4346 15.6522 8.34766 18.2609 6.78246L17.2174 6.26074C17.2174 6.26074 13.5653 8.86943 10.9566 8.34771V9.3912H9.91309L9.39138 8.86948L7.82617 9.91291Z',
        fill: '#FFDA44'
      })
    ]),
    _createElementVNode('defs', null, [
      _createElementVNode('clipPath', { id: 'clip0_4859_73683' }, [
        _createElementVNode('rect', {
          width: '24',
          height: '24',
          fill: 'white'
        })
      ])
    ])
  ]))
}
