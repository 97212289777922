<template>
  <Head>
    <Link rel="icon"
          type="image/x-icon"
          href="/favicon.png"
    />
  </Head>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
import { onMounted } from 'vue'
import { useNuxtApp } from '#imports'
import useTrackScrollDepth from '~~/composables/track-scroll-depth'
import '~/assets/styles/main.css'

const { $plausible } = useNuxtApp()
useTrackScrollDepth()

onMounted(() => {
  $plausible()
})
</script>
