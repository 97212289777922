// Copied from https://github.com/nuxt/movies
// Solves scroll to top on page change

// We cannot leverage vue-router scrollBehavior since the scroll is not on window
export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hooks.hook('page:transition:finish', async () => {
    console.log('Scrolling to top…')
    document.querySelector('[data-scroll]')?.scrollTo({ top: 0, behavior: 'auto' })
  })
})
