<template>
  <component :is="componentToShow"
             class="z-10 block"
             :href="href"
             :download="hrefType === 'download' ? '' : undefined"
             :target="href && href.toLowerCase().startsWith('http') ? '_blank' : ''"
  >
    <component :is="`ButtonBase${buttonType}`"
               :href="href"
               :label="label"
               :disabled="disabled"
               :href-type="hrefType"
               :size="size"
    >
      <slot />
    </component>
  </component>
</template>

<script setup lang="ts">
/**
 * Base Button component
 *
 * This component is used to render the different button types. It is meant to be used for the
 * button types: filled, gradient, outline, link. Those components are not meant to be used
 * directly.
 *
 * The href prop is optional, since some cards are clickable, which makes the underlying cta
 * a button element, without a link. Those without a preset href, have a hrefType prop.
 */
import { computed, resolveComponent } from 'vue'
import { useLinkType } from '../../composables/link-type'
import type { ButtonBaseType, CtaHrefType, CtaSize } from '../../types/button'

interface ButtonBaseProps {
  href?: string
  disabled?: boolean
  label?: string

  hrefType?: CtaHrefType // We don't set a default value, since if it's set we can't override it in the button
  size?: CtaSize
  type: ButtonBaseType
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NuxtLink = resolveComponent('NuxtLink')

const props = withDefaults(defineProps<ButtonBaseProps>(), {
  label: '',
  disabled: false,
  size: 'default',
  type: 'filled',
  href: ''
})

/**
 * We define the hrefType based on the href prop.
 *
 * Depending on the href type, a different icon is rendered on the button. IF
 * a hrefType was already passed to the base component, we use that one.
 */
const hrefType = computed(() => {
  if (props.hrefType) {
    return props.hrefType
  }

  if (props.href && props.href.length > 0) {
    return useLinkType(props.href)
  }

  return 'internal'
})

/**
 * Component to render
 *
 * In order to render NuxtLink as a dynamic component, we need to use
 * the resolveComponent function.
 */
const componentToShow = computed(() => {
  if (props.href && props.href.length > 0) {
    return resolveComponent('NuxtLink')
  }
  return 'button'
})

/**
 * Capitalize Button type
 */
const buttonType = computed(() => {
  return props.type.charAt(0).toUpperCase() + props.type.slice(1)
})
</script>
